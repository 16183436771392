import styled from 'styled-components';


const ContactButton = styled.a`
  border-style: solid;
  border-width: 2px;
  border-color: #007451;
  color: white;
  z-index:-1;
  text-align: center;
  border-radius: 15px;
  font-size: 30px;
  padding: 10px;
  top: 50%;
  text-decoration: none;
  font-weight: bold;
  background-color: #007451;
`

export default ContactButton